import { createGlobalStyle } from "styled-components";

const GlobalStyleWidget = createGlobalStyle`
  body {
    margin: 0;
    font-size: 18px;
    font-family: "Inter";
    background: transparent;
    overflow: hidden;
  }
`;

export default GlobalStyleWidget;
