import React from "react";
import { Box, CircularProgress, Typography, ThemeProvider, CssBaseline } from "@mui/material";
import styled from "@emotion/styled";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { theme } from "../theme";
import share from "../services/share";
import NumeralColor from "./NumeralColor";
import GlobalStyleWidget from "../styles/GlobalStyleWidget";

const detailsData = () => [
  {
    key: "previousClosePrice",
    title: <Trans>PREVIOUS CLOSE</Trans>,
  },
  {
    key: "tradedVolume",
    title: <Trans>VOLUME</Trans>,
  },
  {
    key: "volumeAmount",
    title: <Trans>VOLUME (AMOUNT)</Trans>,
  },
  {
    key: "openPrice",
    title: <Trans>OPEN</Trans>,
    keyDate: "openAt",
  },
  {
    key: "lowestPrice",
    title: <Trans>LOW</Trans>,
    keyDate: "lowestAt",
  },
  {
    key: "highestPrice",
    title: <Trans>HIGH</Trans>,
    keyDate: "highestAt",
  },
];

// const defaultData = {
//   "highestAt": "9:00:00 AM",
//   "highestPrice": 5.875,
//   "lastPrice": "5.83",
//   "lowestAt": "9:00:00 AM",
//   "lowestPrice": 5.83,
//   "openAt": "9:00:00 AM",
//   "openPrice": 5.875,
//   "percentChange": "-1.52",
//   "previousClosePrice": "5.92",
//   "priceChange": "-0.09",
//   "sessionTime": "November 20, 2023 9:00 AM",
//   "tradedVolume": 5977,
//   "volumeAmount": "35K",
// };

class SharePriceDataWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.getData();
    setInterval(() => this.getData(), 60000);
  }

  getData = () => share()
    .getPriceData()
    .then((result) => this.setState({ data: result }));

  renderMiniWidget = () => {
    const { isLight } = this.props;
    const { data } = this.state;
    return (
      <StyledMini isLight={isLight}>
        {_.isEmpty(data) ? (
          <CircularProgress />
        ) : (
          <Box sx={{ position: "relative" }}>
            {
              _.isEmpty(data) &&
              <Typography
                variant="caption"
                position="absolute"
                sx={{
                  top: 0,
                  width: "100%",
                  height: "100%",
                  color: "#355C9F",
                  display: "block",
                  textAlign: "center",
                  backgroundColor: "#355C9F50",
                }}
              >
                <CircularProgress size="1rem" sx={{ svg: { color: "#355C9F" } }} />
              </Typography>
            }
            <Box className="top">
              <Typography fontSize="1em">
                {data.lastPrice || "-"}
              </Typography>
              <Typography fontSize="0.5em" ml="0.5em">
                EUR
              </Typography>
            </Box>
            <Box className="bottom">
              <NumeralColor value={data.priceChange || "-"} />
              <NumeralColor value={data.percentChange || "-"} ml={0.5}>
                ({data.percentChange || "-"} %)
              </NumeralColor>
            </Box>
          </Box>
        )}
      </StyledMini>
    );
  };

  render() {
    const { isMini, isHorizontal, isBig, isLight } = this.props;
    const { data } = this.state;

    return (
      <ThemeProvider theme={theme}>
        {isMini ? (
          <>
            <GlobalStyleWidget />
            {this.renderMiniWidget()}
          </>
        ) : (
          <>
            <CssBaseline />
            <StyledContainer
              isLight={isLight}
              isBig={isBig}
              isHorizontal={isHorizontal}
            >
              <Box p="5px">
                {!isHorizontal && (
                  <Box>
                    <Typography fontSize={12} fontWeight={600}>
                      ALLFUNDS
                    </Typography>
                    <Typography fontSize={12}>
                      AMS:ALLFG - {data.sessionTime}
                    </Typography>
                  </Box>
                )}
                <Box className="values" position="relative">
                  <Box className="main" sx={{ gridColumn: "1/4" }}>
                    {
                      _.isEmpty(data) &&
                      <Typography
                        variant="caption"
                        position="absolute"
                        sx={{ top: 0, color: "#355C9F" }}
                      >
                        <CircularProgress size="1rem" sx={{ svg: { color: "#355C9F" } }} />
                      </Typography>
                    }

                    <Typography variant="caption" position="absolute" sx={{ bottom: 0, right: 0, color: "#355C9F" }}>v2023111804</Typography>
                    <Box>
                      {isHorizontal && (
                        <Typography mb={1.5} mt={0.25}>
                          AMS:ALLFG
                        </Typography>
                      )}
                      <Box>
                        <NumeralColor
                          className="price"
                          value={data.lastPrice || "-"}
                        />
                        <Typography ml={0.5}>EUR</Typography>
                      </Box>
                      <Box>
                        <NumeralColor value={data.priceChange || "-"} />
                        <NumeralColor ml={0.5} value={data.percentChange || "-"}>
                          ({data.percentChange || "-"}
                          %)
                        </NumeralColor>
                      </Box>
                      {isHorizontal && (
                        <Typography mt={1.5} mb={0.25} className="grey">
                          {data.sessionTime || "-"}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {detailsData().map(({ key, title, keyDate }) => (
                    <Box key={key}>
                      <Box>
                        <Typography>{title}</Typography>
                        <Typography className="item">{data[key] || "-"}</Typography>
                        {keyDate && (
                          <Typography className="grey">
                            {data[keyDate] || "-"}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </StyledContainer>
          </>
        )}
      </ThemeProvider>
    );
  }
}

const StyledContainer = styled(Box)`
  overflow: hidden !important;
  background: ${({ isLight }) => (isLight ? "white" : "#1B3053")} !important;
  * p,
  svg {
    color: ${({ isLight }) => (isLight ? "#1B3053" : "white")};
  }
  .grey {
    color: ${({ theme }) => theme.palette.neutral.dark};
  }
  .values {
    display: grid;
    grid-template-columns: ${({ isHorizontal }) => (isHorizontal ? "1.5fr 1fr 1fr 1fr" : "repeat(3, auto)")};
    border-bottom: ${({ isHorizontal, isLight, theme }) => isHorizontal && isLight && `1px solid ${theme.palette.neutral.light}`};
    .main {
      display: grid;
      justify-content: center;
      alig-items: center;
      text-align: center;
      grid-column: ${({ isHorizontal }) => (isHorizontal ? "1/2" : "1/4")};
      grid-row: ${({ isHorizontal }) => isHorizontal && "1/3"};
      border-bottom: ${({ theme, isHorizontal }) => !isHorizontal && `1px solid ${theme.palette.neutral.light}`};
      border-right: ${({ theme, isHorizontal }) => isHorizontal && `1px solid ${theme.palette.neutral.light}`};
      padding: ${({ isHorizontal }) => (isHorizontal ? "0.75rem 0" : "2rem 0")};
      // margin: ${({ isHorizontal }) => isHorizontal && "0 1rem"};
      > div {
        display: grid;
        place-content: center;
        > div {
          display: flex;
          align-items: baseline;
          justify-content: center;
        }
      }
      * {
        font-size: ${({ isBig }) => (isBig ? 1 : 0.75)}rem;
      }
      .price {
        font-size: ${({ isBig }) => (isBig ? 3 : 2)}rem;
      }
    }
    > div:not(.main) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0.75rem 0;
      min-height: ${({ isHorizontal }) => !isHorizontal && "80px"};
      height: 100%;
      ${({ theme, isHorizontal, isLight }) => (isHorizontal || (!isHorizontal && !isLight)
    ? `:nth-child(-n + 4) {
              border-bottom: 1px solid ${theme.palette.neutral.light};
            }`
            : `border-bottom: 1px solid ${theme.palette.neutral.light};`)}
            :nth-child(3n - 1),
            :nth-child(3n - 3) {
              > div {
          width: 100%;
          border-right: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
        }
      }
      .item {
        font-size: ${({ isBig }) => (isBig ? 1.25 : 1)}rem;
        font-weight: 600;
      }
      * {
        font-size: ${({ isBig }) => (isBig ? "1rem" : "12px")};
      }
    }
  }
`;

const StyledMini = styled(Box)`
  border-radius: 5px;
  padding: 5px;
  margin: 0;
  width: fit-content;
  background: ${({ isLight }) => (isLight ? "transparent" : "#1B3053")};
  // border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  display: flex;
  align-items: center;
  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    color: ${({ isLight }) => (isLight ? "#1B3053" : "white")};
  }
  > div > div {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  .top > p {
    color: ${({ isLight }) => (isLight ? "#363636" : "white")};
    line-height: 1;
  }
  .bottom > p {
    font-size: 0.5em;
    font-weight: 600;
    line-height: 1;
  }
`;

// const BoxLoading = styled(Box)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: ${({ theme }) => `${theme.spacing(20)}`};
// `;

export default SharePriceDataWidget;
