import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

function NumeralColor({
  value, children, color, ...rest
}) {
  return (
    <StyledTypography
      currentColor={color || (Number(value) < 0 ? "#f14668" : "#77AB64")}
      {...rest}
    >
      {children || value}
    </StyledTypography>
  );
}

const StyledTypography = styled(Typography)`
  color: ${({ currentColor }) => `${currentColor} !important`};
`;

export default NumeralColor;
